import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebaseConnect, isLoaded, useFirestore, useFirestoreConnect, useFirebase } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import styles from "./PageComponents.styles";
import "./PageComponents.style.css";
import { Input } from "../../../../components/Input/Input";
import { RichTextEditor } from "../../../../components/RichTextEditor/RichTextEditor";
import { convertFromRaw, Editor, EditorState } from "draft-js";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(styles);


function CreateOurMessage({ obituary, onChange }) {
  let firebase = useFirebase();
  const [nameVal, changeName] = useState("");
  const [mailVal, changeMail] = useState("");
  const [phoneVal, changePhone] = useState("");
  const [messageVal, changeMessage] = useState("");
  const [makingMessage, makingMessageChange] = useState(false);
  const [messageSent, messageSentChange] = useState(false);
  const auth = useSelector(state => state.firebase.auth);

  function onNameChange(e) {
    return changeName(e && e.target && e.target.value);
  }

  function onMailChange(e) {
    return changeMail(e && e.target && e.target.value);
  }

  function onPhoneChange(e) {
    return changePhone(e && e.target && e.target.value);
  }

  function onCondolenceChange(val) {
    return changeMessage(val);
  }

  function resetInput(e) {
    changeMessage("");
    changeName("");
    changeMail("");
    changePhone("");
  }

  async function addComment({ message, mail, name, phone }) {
    makingMessageChange(true);
    var createOurMessage = firebase.functions().httpsCallable("createOurMessage");
    await createOurMessage({
      message,
      mail,
      name,
      phone
    });
    makingMessageChange(false);
    messageSentChange(true);
  }

  return (
    <div className={"container createCondolences"}>
      <h4>Escríbenos un comentario</h4>
      <Input label={"Nombre"} value={nameVal} onChange={onNameChange}/>
      {auth ? null : <Input label={"Correo"} value={mailVal} onChange={onMailChange}/>}
      <Input label={"Phone"} value={phoneVal} onChange={onPhoneChange}/>

      <p> Condolencia : </p>
      <div style={{
        backgroundColor: "white",
        padding: "5px"
      }}>
        <RichTextEditor value={messageVal} onChange={onCondolenceChange}
                        placeholder={"Escribe aquí un comentario para nosotros"}/>
      </div>
      {/*<Input label={"Condolencia"} type="textarea" value={condolenceVal} onChange={onCondolenceChange}/>*/}

      <div className={"createButtons"}>
        <button onClick={resetInput}>Cancelar</button>
        <button onClick={() => addComment({ name: nameVal, mail: mailVal, message: messageVal, phone: phoneVal })}>
          Enviar Comentario
        </button>
      </div>

      { makingMessage ? <LoadingSpinner/> : null }
      {messageSent ? <p> Tu mensage a sido enviado con éxito!</p> : null}
    </div>
  );
}

export default CreateOurMessage;
