import React from "react";

import "./Input.css"
import { CDatePicker } from "../CDatePicker/CDatePciker";
import { TranslatableText } from "../TranslatableText/TranslatableText";

export function Input({ reference, label, onChange, value, type, rows = 10,  ...props}) {
  const labelDOM = (<TranslatableText>{label}</TranslatableText>);
  if (type === "textarea"){
    return (
      <div className={"cinput"}>
        {label ? <span> {labelDOM} : </span> : null}
        <textarea rows={rows} value={value} onChange={onChange} {...props}/>
      </div>
    );
  } else if (type === "date"){
    return (
      <div className={"cinput"}>
        {label ? <span> {labelDOM} : </span> : null}
        <CDatePicker value={value} onChange={onChange}/>
      </div>
    )
  } else {
    return (
      <div className={"cinput"}>
        {label ? <span> {labelDOM} : </span> : null}
        <input ref={reference} type={type} value={value} onChange={onChange} {...props}/>
      </div>
    );
  }
}
