import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH
} from "constants/paths";
import styles from "./Us.styles";
import "./Us.css";
import { Header } from "../../../../components/Header/Header";
import { Tawk } from "../../../../components/Tawk/Tawk";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../../../constants/ScreenSizes";

const authWrapperUrl = "https://github.com/mjrussell/redux-auth-wrapper";
const reactRouterUrl = "https://github.com/ReactTraining/react-router";

const useStyles = makeStyles(styles);


function Us() {

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  let usDivStyle = "usDivDesktop";
  if (tabletScreen || phoneScreen)
    usDivStyle = "usDivPhone";

  return (
    <div className={"usTopDiv"}>
      <div className={usDivStyle}>
        <Tawk/>
        <Header text={"NOSOTROS"}/>
        <div className={"usmain"}>

          <h2> Historia </h2>
          <p>
            <b>Post Mortem Digital Cemetery </b> Inicia en Diciembre de 2018. La empresa ofrece servicios digitales
            funerarios que permiten honrar y venerar a los que partieron y perpetuar el sensible recuerdo en familiares
            y
            amigos.
          </p>

          <p>
            <b>Post Mortem Digital Cemetery </b> es una empresa que ofrece el servicio de cementerio digital, es decir,
            una
            plataforma (sitio web) donde los recuerdos de su ser querido estarán almacenados de una manera permanente.
          </p>

          <p>
            <b>Post Mortem Digital Cemetery </b> cuenta con un espacio para todo aquel que desee escribir un obituario,
            una
            nota de pésame, un comentario, una anécdota e incluso postear una foto para compartir con las personas que
            conocieron al difunto, esto con el fin de honrar la vida, las enseñanzas y todo el legado que les dejó. Asi
            como
            tambien, permite tener acceso a galerias de imagenes y a la línea de la vida (video).
          </p>

          <p>
            <b>Post Mortem Digital Cemetery </b> proporcionará la información puntual sobre el día, el lugar y hora de
            los
            servicios funerarios y morada final, de acuerdo a las creencias, ideología o religión de la familia.
          </p>

          <p>
            <b>Post Mortem Digital Cemetery </b> cuenta con servicio de florería, música y comida entre otros.
          </p>

          <h2> Missión </h2>
          <p>
            Ante el devastador sentimiento que embarga a nuestros clientes en un momento tan difícil. Como la pérdida de
            un ser querido, es el medio que ofrece servicios funerarios digitales de información, sea cual fuere el
            lugar
            donde se ubiquen, dando a conocer respetuosamente y cálidamente, la vida de quien ha partido.
          </p>

          <h2> Visión </h2>
          <p>
            Consolidar su presencia a nivel nacional e internacional como una empresa que ofrece servicios funerarios
            digitales de información. que permitan honrar a los que partieron y perpetuar su sensible recuerdo a través
            del tiempo.
          </p>
        </div>


      </div>
    </div>
  );
}

export default Us;
