import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TranslatableText } from "./components/TranslatableText/TranslatableText";
import React from "react";
import LanguageDetector from 'i18next-browser-languagedetector';
import { CustomButton } from "./components/CustomButton/CustomButton";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Us": "Us",
      "Contact" : "Contact",
      "Buy" : "Buy",
      "Account" : "Account",
      "Log In" : "Log In",
      "Log Out" : "Log Out",
      "Sign In" : "Sign In",
      "Phrase" : "Where his eternal rest will be our forever memory",
      "Save" : "Save",
      "Saving" : "Saving",
      "Apply" : "Apply",
      "Account Info" : "Account Info",
      "Buy obituary" : "Buy obituary",
      "Share link" : "Share link",
      "Public" : "Public",
      "Edit" : "Edit",
      "View page" : "View page",
      "Only with link" : "Only with link (Only someone with the link can see the publication)",
      "Security" : "Security",
      "Obituary" : "Obituary",
      "Obituaries" : "Obituaries",
      "Gallery":"Gallery",
      "Thoughts" :"Thoughts",
      "Videos" : "Videos",
      "Events" : "Events",
      "Name" : "Name",
      "Birthdate" : "Birthdate",
      "Date of death" : "Date of death",
      "Write here the biography of your love one." : "Write here the biography of your love one." ,
      "Biography" : "Biography",
      "Description" : "Description",
      "Event date" : "Event date",
      "Location" : "Location",
      "Mail" : "Mail"
    }
  },
  es: {
    translation: {
      "Us": "Nosotros",
      "Buy" : "Comprar",
      "Contact" : "Contacto",
      "Account" : "Cuenta",
      "Log In" : "Iniciar Sessión",
      "Log Out" : "Salir",
      "Sign In" : "Iniciar Sessión",
      "Phrase" : "Dónde su eterno descanso, será nuestro infinito recuerdo",
      "Save" : "Guardar",
      "Saving" : "Guardando",
      "Apply" : "Aplicar",
      "Account Info" : "Información de la cuenta",
      "Buy obituary" : "Comprar obituario",
      "Share link" : "Compartir link",
      "Public" : "Público",
      "Edit" : "Editar",
      "View page" : "Ver página",
      "Only with link" : "Solo con Link (Solo las personas con este link secreto pueden ver la publicación)",
      "Security" : "Seguridad",
      "Obituary" : "Obituario",
      "Obituaries" : "Obituarios",
      "Gallery":"Galería",
      "Thoughts" :"Pensamientos",
      "Videos" : "Videos",
      "Events" : "Eventos",
      "Name" : "Nombre",
      "Birthdate" : "Fecha de nacimiento",
      "Date of death" : "Fecha de defunción",
      "Write here the biography of your love one." : "Escribe aquí la biografía de tu ser querido.",
      "Biography" : "Biografía",
      "Description" : "Descripción",
      "Event date" : "Fecha del evento",
      "Location" : "Ubicación",
      "Mail" : "Correo"
    }
  }
};

console.log(navigator.language);

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection : {
      // order and from where user language should be detected
      order: ['navigator', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;