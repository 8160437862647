import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH
} from "constants/paths";
import styles from "./Contact.styles";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateOurMessage from "../PageComponents/PageComponents";
import { Tawk } from "../../../../components/Tawk/Tawk";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../../../constants/ScreenSizes";

const authWrapperUrl = "https://github.com/mjrussell/redux-auth-wrapper";
const reactRouterUrl = "https://github.com/ReactTraining/react-router";

const useStyles = makeStyles(styles);


function Contact() {


  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  let contactDivStyle = "topContactDesktop";
  if (tabletScreen || phoneScreen)
    contactDivStyle = "topContactPhone";

  return (
    <div className={"topContactDiv"}>
      <div className={contactDivStyle}>
        <Tawk/>
        <h2> Contactanos </h2>

        <img src={"/logo.png"} width={"200px"}/>


        <iframe className={"contactMap"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49253976.50994888!2d-114.93036805488353!3d18.703198411647175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ba2f42b635eac3%3A0xb624c34752328808!2sLos%20Mochis%2C%20Sinaloa!5e0!3m2!1sen!2smx!4v1573812538588!5m2!1sen!2smx"/>

        <div className={"ourmessagesArea"}>
          <CreateOurMessage/>
        </div>

        <p> CONTACTO </p>
        <p> Cel: +52 1 (668) 172 0916 </p>
        <p> Cel: +52 1 (449) 181 5465 </p>

        <div className="icons">
          <a href="tel:6681720916">
            <FontAwesomeIcon icon={"phone"}/>
          </a>
          <a href="https://www.facebook.com/postmortemdigitalcemetery/">
            <FontAwesomeIcon icon={["fab", "facebook-square"]}/>
          </a>
          <a href="https://api.whatsapp.com/send?phone=+5216681720916">
            <FontAwesomeIcon icon={["fab", "whatsapp"]}/>
          </a>
          <a href="mailto:antiguedadesoro@gmail.com">
            <FontAwesomeIcon icon={"envelope"}/>
          </a>
        </div>


      </div>
    </div>
  );
}

export default Contact;
