import React from 'react'
import PropTypes from 'prop-types'
import Navbar from 'containers/Navbar'
import { Notifications } from 'modules/notification'
import { makeStyles } from '@material-ui/core/styles'
import styles from './CoreLayout.styles'
import { useMediaQuery } from 'react-responsive'


import './CoreLayout.css'
import { SCREEN_SIZE } from "../../constants/ScreenSizes";

const useStyles = makeStyles(styles);

function CoreLayout({ children }) {
  const classes = useStyles();

  let style = {

  };

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  return (
    <div className={"mainDiv"} style={{
      height : "100%"
    }} >
      <div className={"coreLayoutDiv"}>
        <Navbar />
        <div style={{
          display : "flex",
          justifyContent : "center",
          alignItems : "stretch",
          flexGrow: "9"
        }}>
          <div className={"middle"}>{children}</div>
        </div>
        <div className={"footer"}>
          © postmortem.com.mx
        </div>
      </div>
      <Notifications />
    </div>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CoreLayout
