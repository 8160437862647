
import React from "react";
import "./CustomButton.css";

export function CustomButton({ children,  onClick, disabled=false }) {


  return (
    <button className={"CustomButton"} onClick={onClick}  disabled={disabled}> {children} </button>
  );
}
