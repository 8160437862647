import React from "react";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH
} from "constants/paths";
import "./Header.css";


export function Header({ text, children }) {

  return (
    <div className={"topImage"}>

      {/*<img src={require("../../../../Assets/pos5.png")}/>*/}
      {/*<img src={"/pos5.png"}/>*/}
      <h2>{text}</h2>
    </div>
  );
}

