import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export function TranslatableText({ children }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {t(children)}
    </>
  );
}

