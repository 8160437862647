export default () => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    // backgroundColor: theme.palette.primary1Color // Update this to change navbar color
  },
  signIn: {
    color: 'white',
    textDecoration: 'none',
    alignSelf: 'center'
  }
})
