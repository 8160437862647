import React, { useImperativeHandle, useState, forwardRef } from "react";
import { useFirebaseConnect, isLoaded, useFirebase } from "react-redux-firebase";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { Input } from "../../components/Input/Input";
import "./SearchBar.css";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { SmallObituaryPreview } from "./components/SmallObituaryPreview";

const obituaryQuery = (query, part) => {
  return {
    collection: "obituaries",
    where: [
      ["namePart" + part, ">=", query]
    ]
    ,
    orderBy: ["namePart" + part],
    limit: 5,
    storeAs: "searchObituaries"
    //limitTo: 10
  };
};

function containsObituary_ArrObituary(arr, obituaryOther) {
  for (let i = 0; i < arr.length; i++) {
    let obituary = arr[i];
    if (obituary.id === obituaryOther.id)
      return true;
  }
  return false;
}

function shouldKeepObituary_ObituaryQuery(obituary, query) {
  for (let i = 1; i < 4; i++) {
    if (obituary["namePart" + i].startsWith(query)) {
      return true;
    }
  }
  return false;
}

export const SearchBar = ({}) => {

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  const [query, changeQuery] = useState("");

  const [obituaryList, obituaryListChange] = useState([]);



  const firebase = useFirebase();
  const firestore = useFirestore();

  async function queryDB(query, part) {
    let querySnapshot = await firestore.collection("obituaries").where("namePart" + part, ">=", query.toLowerCase()).limit(5).get();
    let obituaryList = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      obituaryList.push({ id: doc.id, ...doc.data() });
      //console.log(doc.id, " => ", doc.data());
    });
    return obituaryList;
  }

  function mergeObituaryLists(lists, query) {
    const obituaryList = [];
    for (let i = 0; i < 3; i++) {
      let obituaryListPart = lists[i];
      obituaryListPart.forEach((obituary) => {
        if (!containsObituary_ArrObituary(obituaryList, obituary)) {
          if (shouldKeepObituary_ObituaryQuery(obituary, query)) {
            obituaryList.push(obituary);
          }
        }
      });
    }
    obituaryListChange(obituaryList);
  }

  async function makeAllQueries(query) {
    if (query !== "") {
      let list1 = await queryDB(query, 1);
      let list2 = await queryDB(query, 2);
      let list3 = await queryDB(query, 3);
      mergeObituaryLists([list1, list2, list3], query);
    } else {
      obituaryListChange([]);
    }
  }


  function onQueryChange(e) {
    console.log("query changed", {q:e.target.value});
    makeAllQueries(e.target.value).then();
    return changeQuery(e && e.target && e.target.value);
  }

  let searchObituariesDOM = [];
  if (obituaryList) {
    searchObituariesDOM = obituaryList.map((obituary) => (<SmallObituaryPreview key={obituary.id} obituary={obituary}/>));
  }

  return (
    <div className={"SearchBarDiv"}>

      <Input placeholder={"Buscar"} style={{
        width: "270px"
      }} value={query} onChange={onQueryChange}/>

      <div className={"resultsSearchBar"}>
        {searchObituariesDOM}
      </div>

    </div>
  );
};

