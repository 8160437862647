import React from "react";
import ReactDOM from "react-dom";
import { initScripts } from "./utils";
import createStore from "./store/createStore";
import { version } from "../package.json";
import { env } from "./config";
import App from "./containers/App";
import "./index.css";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faCoffee,
  faCircle,
  faStar,
  faEyeSlash,
  faPhone,
  faEnvelope,
  faTrash,
  faPlusCircle,
  faCopy,
  faArrowUp,
  faArrowDown,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import './i18n';

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version;
window.env = env;
initScripts();

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null }
};
const store = createStore(initialState);
const routes = require("./routes/index").default(store);


library.add(fab, faCheckSquare, faCoffee, faCircle, faStar, faEyeSlash, faPhone, faEnvelope, faTrash, faPlusCircle, faCopy, faArrowUp, faArrowDown, faArrowAltCircleRight, faArrowAltCircleLeft, faEdit);

ReactDOM.render(
  <App store={store} routes={routes}/>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
