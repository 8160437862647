import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, isLoaded, useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import { CONTACT, LOGIN_PATH, US,ACCOUNT_PATH } from "../../constants/paths";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";
import { useSelector } from "react-redux";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";

const useStyles = makeStyles(() => ({
  buttonRoot: {
    color: "white"
  }
}));

function AccountMenu() {
  const classes = useStyles();
  const [anchorEl, setMenu] = useState(null);
  const history = useHistory();
  const firebase = useFirebase();

  const auth = useSelector(state => state.firebase.auth);
  const authExists = isLoaded(auth) && !isEmpty(auth);

  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  function closeAccountMenu(e) {
    setMenu(null);
  }

  function handleMenu(e) {
    setMenu(e.target);
  }

  function handleLogout() {
    return firebase.logout().then(() => {
      closeAccountMenu();
      history.push("/");
    });
  }

  function goToAccount() {
    closeAccountMenu();
    history.push(ACCOUNT_PATH);
  }

  function goToUs() {
    closeAccountMenu();
    history.push(US);
  }

  function goToContact() {
    closeAccountMenu();
    history.push(CONTACT);
  }

  function goToSignIn() {
    closeAccountMenu();
    history.push(LOGIN_PATH);
  }


  let Icon = (<AccountCircle style={{ color: "#C73515" }}/>);
  if (phoneScreen){
    Icon = (<MenuIcon style={{ color: "#C73515" }}/>);
  }

  return (
    <Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-appbar" : null}
        aria-haspopup="true"
        onClick={handleMenu}
        classes={{ root: classes.buttonRoot }}>
        {Icon}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}>
        <MenuItem onClick={goToUs}><TranslatableText>Us</TranslatableText></MenuItem>
        <MenuItem onClick={goToContact}><TranslatableText>Contact</TranslatableText></MenuItem>
        <MenuItem onClick={goToAccount}><TranslatableText>Buy</TranslatableText></MenuItem>

        {authExists ? (<MenuItem onClick={goToAccount}><TranslatableText>Account</TranslatableText></MenuItem>) : null}
        {authExists ? (<MenuItem onClick={handleLogout}><TranslatableText>Log Out</TranslatableText></MenuItem>) : null}
        {authExists ? null : <MenuItem onClick={goToSignIn}><TranslatableText>Log In</TranslatableText></MenuItem> }

      </Menu>
    </Fragment>
  );
}

AccountMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired // from enhancer (withRouter)
  }),
  firebase: PropTypes.shape({
    logout: PropTypes.func.isRequired // from enhancer (withFirebase)
  })
};

export default AccountMenu;
