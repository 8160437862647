import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../../constants/ScreenSizes";
import "./SmallObituaryPreview.css";
import { OBITUARY_PATH, US } from "../../../constants/paths";
import { useHistory } from "react-router-dom";

export function SmallObituaryPreview({ obituary }) {

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  const history = useHistory();

  function goObituary() {
    history.push(OBITUARY_PATH + "/" + obituary.id);
  }

  return (
    <div className={"smallObituaryPreview"} onClick={goObituary}>


      <img src={obituary.mainphoto} style={{
        maxHeight: "25px"
      }}/>

      <div className={"smallObituaryPreviewDescription"}>
        <p>
        {obituary.name}
        </p>
      </div>


    </div>
  );
}
