/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Config for firebase
export const firebase = {
  apiKey: "AIzaSyCZcZGm-P4CZdtLX1R6Xusn_poXT9Mil-I",
  authDomain: "postmortem-5b6f7.firebaseapp.com",
  databaseURL: "https://postmortem-5b6f7.firebaseio.com",
  projectId: "postmortem-5b6f7",
  storageBucket: "postmortem-5b6f7.appspot.com",
  messagingSenderId: "435684084054",
  appId: "1:435684084054:web:e87af17f556749fc19eef7",
  measurementId: "G-9BRCQN5F99"
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: false, // Store in Firestore instead of Real Time DB
  enableLogging: false
}

export default {
  env,
  firebase,
  reduxFirebase
}
