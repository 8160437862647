import React from 'react';
import {Editor, EditorState, ContentState, convertToRaw, convertFromRaw  } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Editor as EditorW } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



export function RichTextEditor({ value, onChange, placeholder, readOnly=false, toolbarHidden=false }) {

  let defaultState =  EditorState.createEmpty();
  if (value){
    console.log("value", {value:value})
    defaultState = EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
  }
  const [editorState, setEditorState] = React.useState(
    () =>  defaultState
  );

  return <EditorW
    placeholder={placeholder}
    editorState={editorState} onEditorStateChange={(e) => {
    onChange(JSON.stringify(convertToRaw(e.getCurrentContent())));
    setEditorState(e);
  }}
    readOnly={readOnly}
    toolbarHidden={toolbarHidden}
  />;
}
