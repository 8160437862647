import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH
} from "constants/paths";
import styles from "./HomePage.styles";
import "./HomePage.css";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../../../constants/ScreenSizes";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { TranslatableText } from "../../../../components/TranslatableText/TranslatableText";

const authWrapperUrl = "https://github.com/mjrussell/redux-auth-wrapper";
const reactRouterUrl = "https://github.com/ReactTraining/react-router";

const useStyles = makeStyles(styles);

function Home() {
  const classes = useStyles();

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });

  let style = {
    width: "500px",
    filter: "drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
};

  if (tabletScreen)
    style.width = "70%";
  if (phoneScreen)
    style.width = "80%";

  return (
    <div className={"homediv"}>
      <div className={"homediv2"}>

        <img style={style} src={"/logoBlanco.png"}/>

        <div className={"textDiv"}>
          <h2> <TranslatableText>Phrase</TranslatableText>  </h2>
        </div>

        <SearchBar  />

      </div>
    </div>
  );
}

export default Home;
