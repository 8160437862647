import React, { useState } from "react";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import DateFnsUtils from '@date-io/date-fns';

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export function CDatePicker({ value, onChange }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker value={value} onChange={onChange} format={"dd/MM/yyyy"} />
    </MuiPickersUtilsProvider>
  );
}
