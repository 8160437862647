import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import AccountMenu from "./AccountMenu";
import styles from "./Navbar.styles";

import "./Navbar.css";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";

import { LIST_PATH, CONTACT, LOGIN_PATH, US,ACCOUNT_PATH } from "../../constants/paths";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";


const useStyles = makeStyles(styles);


function Navbar() {
  const classes = useStyles();

  // Get auth from redux state
  const auth = useSelector(state => state.firebase.auth);
  const authExists = isLoaded(auth) && !isEmpty(auth);

  const desktopScreen = useMediaQuery({ query: SCREEN_SIZE.DESKTOP });
  const tabletScreen = useMediaQuery({ query: SCREEN_SIZE.MEDIUM });
  const phoneScreen = useMediaQuery({ query: SCREEN_SIZE.PHONE });


  let navMenu = (<>
      <a className={"Menulinks"} href={US}> <TranslatableText>Us</TranslatableText>  </a>
      <a className={"Menulinks"} href={CONTACT}> <TranslatableText>Contact</TranslatableText> </a>
      <a className={"Menulinks"} href={ACCOUNT_PATH}> <TranslatableText>Buy</TranslatableText> </a>
    </>
  );

  let classNavbarMiddleBySize = "navbarMiddleDesktop";

  if (phoneScreen) {
    navMenu = null;
    classNavbarMiddleBySize = "navbarMiddlePhone";
  }

  return (
    <div style={{ zIndex: 1100 }}>
      <AppBar className={classes.appBar} style={{ backgroundColor: "#FFFFFF", zIndex: 1100 }}>
        <Toolbar style={{ zIndex: 1100 }}>
          <div className={"navbarDiv"}>
            <div className={"navbarMiddle " + classNavbarMiddleBySize}>
              <div className={"navbarLogoDiv"}>
                <a href={"/"}>
                  <img src={require("../../Assets/logo.png")} width="100px"/>
                </a>
              </div>

              <div className={"navbarLinksDiv"}>
                {navMenu}
                {(authExists || phoneScreen) ? (
                  <AccountMenu/>
                ) : (
                  <Button
                    style={{ color: "#C73515" }}
                    className={classes.signIn}
                    component={Link}
                    to={LOGIN_PATH}
                    data-test="sign-in">
                    <TranslatableText>Sign In</TranslatableText>
                  </Button>
                )}
              </div>
            </div>
          </div>

        </Toolbar>
      </AppBar>

    </div>
  );
}

export default Navbar;
